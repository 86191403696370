<template>
  <BaseListPage locale-section="pages.countries" route="country">
    <CountriesList />
  </BaseListPage>
</template>

<script>
export default {
  name: "CountriesPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    CountriesList: () => import("./CountriesList")
  }
};
</script>
